import ApiException from '@exceptions/ApiExceptions';
import axiosInstance, {
  memoizedAxiosGet,
} from 'src/common/services/axiosInterceptor';
import * as Sentry from '@sentry/browser';
import { ClientInfo } from '../store/ClientSlice';
import axios from 'axios';

interface Error {
  response: {
    status: number;
    data: {
      detail: string;
    };
  };
}
export interface Booking {
  start_time: string;
  service_id: number;
  comment: string;
  provider_id: number;
  client_id: number;
  branch_id: number;
  create_username: string;
}

export interface BookingWithoutTime {
  service_id: number;
  comment: string;
  provider_id: number;
  client_id: number;
  branch_id: number;
  create_username: string;
}
export interface BookingState {
  status: string;
  create_username: string;
}
interface CheckInParams {
  sequence: number;
  name: string;
  appointment_code: string;
  branch_code: string;
  patient_number: string;
  patient_name: string;
  careprovider_name: string;
  labtest_no: string;
  encounter_no: string;
  prescription_no: string;
  room_id: number;
  stage_id: number;
  checkin_time: string | null;
  clinic_time: string | null;
  room_time: string | null;
  create_username: string;
}

export interface TransitionParamsToCheckout {
  room_id: number;
  stage_id: number;
  write_username: string;
}
export interface TicketHistoryUpdate {
  clinic_time?: string;
  clinic_duration?: number;
  room_time?: string;
  room_duration?: number;
  status_time?: string;
  status_duration?: number;
  write_username: string;
}
export interface TransitionParams {
  careprovider_name: string;
  room_id: number;
  stage_id: number;
  room_time?: string;
  clinic_time?: string;
  room_duration?: number;
  clinic_duration?: number;
  status_time?: string;
  status_duration?: number;
  write_username: string;
}
export interface TransitionParamsByDragging {
  room_id: number;
  stage_id: number;
  room_time: string;
  status_time: string;
  clinic_time?: string;
  clinic_duration?: number;
  write_username: string;
}

export const fetchAllBranches = async () => {
  try {
    const response = await memoizedAxiosGet(
      `${process.env.NEXT_PUBLIC_REACT_APP_BACKEND_URL}/branches`,
    );
    return response;
  } catch (error: unknown) {
    const customError = error as Error;
    Sentry.captureException(customError);
    const statusCode =
      customError.response && customError.response.status
        ? customError.response.status
        : undefined;
    throw new ApiException(statusCode);
  }
};
export const fetchTodayBookings = async (
  branchId: number,
  status: string,
  startOfToday: string,
  endOfToday: string,
) => {
  try {
    const response = await axiosInstance.get(
      `${process.env.NEXT_PUBLIC_REACT_APP_BACKEND_URL}/bookings?branch_id=${branchId}&status=${status}&date_from=${startOfToday}&date_to=${endOfToday}`,
    );
    return response.data;
  } catch (error: unknown) {
    const customError = error as Error;
    Sentry.captureException(customError);
    const statusCode =
      customError.response && customError.response.status
        ? customError.response.status
        : undefined;
    throw new ApiException(statusCode);
  }
};

export const findOrCreateClient = async (client: ClientInfo) => {
  try {
    const response = await axiosInstance.post(
      `${process.env.NEXT_PUBLIC_REACT_APP_BACKEND_URL}/clients`,
      client,
    );
    return response;
  } catch (error: unknown) {
    const customError = error as Error;
    Sentry.captureException(customError);
    const statusCode =
      customError.response && customError.response.status
        ? customError.response.status
        : undefined;
    throw new ApiException(statusCode);
  }
};
export const getClientById = async (id: number) => {
  try {
    const response = await axiosInstance.get(
      `${process.env.NEXT_PUBLIC_REACT_APP_BACKEND_URL}/clients/${id}`,
    );
    return response;
  } catch (error: unknown) {
    const customError = error as Error;
    Sentry.captureException(customError);
    const statusCode =
      customError.response && customError.response.status
        ? customError.response.status
        : undefined;
    throw new ApiException(statusCode);
  }
};

export const updateClient = async (id: number, data: ClientInfo) => {
  try {
    const response = await axiosInstance.put(
      `${process.env.NEXT_PUBLIC_REACT_APP_BACKEND_URL}/clients/${id}`,
      data,
    );
    return response;
  } catch (error: unknown) {
    const customError = error as Error;
    Sentry.captureException(customError);
    const statusCode =
      customError.response && customError.response.status
        ? customError.response.status
        : undefined;
    throw new ApiException(statusCode);
  }
};

export const getClientByEmail = async (email: string) => {
  try {
    const response = await memoizedAxiosGet(
      `${process.env.NEXT_PUBLIC_REACT_APP_BACKEND_URL}/clients/email/${email}`,
    );
    return response;
  } catch (error: unknown) {
    const customError = error as Error;
    Sentry.captureException(customError);
    const statusCode =
      customError.response && customError.response.status
        ? customError.response.status
        : undefined;
    throw new ApiException(statusCode);
  }
};
export const createBooking = async (booking: Booking, isFollowUp = false) => {
  try {
    const response = isFollowUp
      ? await axiosInstance.post(
          `${process.env.NEXT_PUBLIC_REACT_APP_BACKEND_URL}/bookings?is_follow_up=true`,
          booking,
        )
      : await axiosInstance.post(
          `${process.env.NEXT_PUBLIC_REACT_APP_BACKEND_URL}/bookings`,
          booking,
        );
    return response;
  } catch (error: unknown) {
    const customError = error as Error;
    Sentry.captureException(customError);

    customError.response && customError.response.status
      ? customError.response.status
      : undefined;
    throw customError; //new ApiException(statusCode);
  }
};
export const updateBookingById = async (
  id: number,
  booking:
    | Booking
    | BookingWithoutTime
    | BookingState
    | { provider_id: number },
) => {
  try {
    const response = await axiosInstance.put(
      `${process.env.NEXT_PUBLIC_REACT_APP_BACKEND_URL}/bookings/${id}`,
      booking,
    );
    return response;
  } catch (error: unknown) {
    const customError = error as Error;
    Sentry.captureException(customError);
    const message = customError.response && customError.response.data.detail;
    throw new ApiException(undefined, message);
  }
};

export const fetchBookings = async (status: string) => {
  try {
    const response = await axiosInstance.get(
      `${process.env.NEXT_PUBLIC_REACT_APP_BACKEND_URL}/bookings?status=${status}`,
    );
    return response;
  } catch (error) {
    const customError = error as Error;
    Sentry.captureException(customError);
    const statusCode =
      customError.response && customError.response.status
        ? customError.response.status
        : undefined;
    throw new ApiException(statusCode);
  }
};
export const fetchBookingsByBranch = async (
  branchId: number,
  firstStatus: string,
  secondStatus: string,
) => {
  try {
    const response = await axiosInstance.get(
      `${process.env.NEXT_PUBLIC_REACT_APP_BACKEND_URL}/bookings?branch_id=${branchId}&status=${firstStatus}&status=${secondStatus}`,
    );
    return response;
  } catch (error) {
    const customError = error as Error;
    Sentry.captureException(customError);
    const statusCode =
      customError.response && customError.response.status
        ? customError.response.status
        : undefined;
    throw new ApiException(statusCode);
  }
};
export const fetchBookingsByBranchAndTime = async (
  branchId: number,
  startTime: string,
  endTime: string,
  firstStatus: string,
  secondStatus: string,
  thirdStatus: string,
) => {
  try {
    const response = await axiosInstance.get(
      `${process.env.NEXT_PUBLIC_REACT_APP_BACKEND_URL}/bookings?branch_id=${branchId}&date_from=${startTime}&date_to=${endTime}&status=${firstStatus}&status=${secondStatus}&status=${thirdStatus}`,
    );
    return response;
  } catch (error) {
    const customError = error as Error;
    Sentry.captureException(customError);
    const statusCode =
      customError.response && customError.response.status
        ? customError.response.status
        : undefined;
    throw new ApiException(statusCode);
  }
};

export const fetchBookingByBranchAndBoookingCode = async (
  branchId: number,
  bookingCode: string,
) => {
  try {
    const response = await axiosInstance.get(
      `${process.env.NEXT_PUBLIC_REACT_APP_BACKEND_URL}/bookings?branch_id=${branchId}&booking_code=${bookingCode}`,
    );
    return response;
  } catch (error) {
    const customError = error as Error;
    Sentry.captureException(customError);
    const statusCode =
      customError.response && customError.response.status
        ? customError.response.status
        : undefined;
    throw new ApiException(statusCode);
  }
};
export const fetchBookingByBoookingCode = async (bookingCode: string) => {
  try {
    const response = await axiosInstance.get(
      `${process.env.NEXT_PUBLIC_REACT_APP_BACKEND_URL}/bookings?booking_code=${bookingCode}`,
    );
    return response;
  } catch (error) {
    const customError = error as Error;
    Sentry.captureException(customError);
    const statusCode =
      customError.response && customError.response.status
        ? customError.response.status
        : undefined;
    throw new ApiException(statusCode);
  }
};

export const fetchAllServices = async () => {
  try {
    const response = await memoizedAxiosGet(
      `${process.env.NEXT_PUBLIC_REACT_APP_BACKEND_URL}/services`,
    );
    return response.data;
  } catch (error) {
    const customError = error as Error;
    Sentry.captureException(customError);
    const statusCode =
      customError.response && customError.response.status
        ? customError.response.status
        : undefined;
    throw new ApiException(statusCode);
  }
};

export const fetchServicesByBranch = async (branchId: number) => {
  try {
    const response = await memoizedAxiosGet(
      `${process.env.NEXT_PUBLIC_REACT_APP_BACKEND_URL}/branches/${branchId}/services`,
    );
    return response;
  } catch (error) {
    const customError = error as Error;
    Sentry.captureException(customError);
    const statusCode =
      customError.response && customError.response.status
        ? customError.response.status
        : undefined;
    throw new ApiException(statusCode);
  }
};
export const fetchProvidersByBranch = async (branchId: number) => {
  try {
    const response = await memoizedAxiosGet(
      `${process.env.NEXT_PUBLIC_REACT_APP_BACKEND_URL}/branches/${branchId}/providers`,
    );
    return response;
  } catch (error) {
    const customError = error as Error;
    Sentry.captureException(customError);
    const statusCode =
      customError.response && customError.response.status
        ? customError.response.status
        : undefined;
    throw new ApiException(statusCode);
  }
};
export const fetchBookingById = async (id: number) => {
  try {
    const response = await axiosInstance.get(
      `${process.env.NEXT_PUBLIC_REACT_APP_BACKEND_URL}/bookings/${id}`,
    );
    return response;
  } catch (error) {
    const customError = error as Error;
    Sentry.captureException(customError);
    const statusCode =
      customError.response && customError.response.status
        ? customError.response.status
        : undefined;
    throw new ApiException(statusCode);
  }
};

export const cancelBooking = async (
  id: number,
  params: { status: string; cancel_reason: string },
) => {
  try {
    const response = await axiosInstance.put(
      `${process.env.NEXT_PUBLIC_REACT_APP_BACKEND_URL}/bookings/${id}`,
      params,
    );
    return response;
  } catch (error) {
    const customError = error as Error;
    Sentry.captureException(customError);
    const statusCode =
      customError.response && customError.response.status
        ? customError.response.status
        : undefined;
    throw new ApiException(statusCode);
  }
};

export const fetchBranchById = async (id: number) => {
  try {
    const response = await memoizedAxiosGet(
      `${process.env.NEXT_PUBLIC_REACT_APP_BACKEND_URL}/branches/${id}`,
    );
    return response;
  } catch (error) {
    const customError = error as Error;
    Sentry.captureException(customError);
    const statusCode =
      customError.response && customError.response.status
        ? customError.response.status
        : undefined;
    throw new ApiException(statusCode);
  }
};
export const fetchServiceById = async (id: number) => {
  try {
    const response = await memoizedAxiosGet(
      `${process.env.NEXT_PUBLIC_REACT_APP_BACKEND_URL}/services/${id}`,
    );
    return response;
  } catch (error) {
    const customError = error as Error;
    Sentry.captureException(customError);
    const statusCode =
      customError.response && customError.response.status
        ? customError.response.status
        : undefined;
    throw new ApiException(statusCode);
  }
};
export const fetchProviderById = async (id: number) => {
  try {
    const response = await memoizedAxiosGet(
      `${process.env.NEXT_PUBLIC_REACT_APP_BACKEND_URL}/providers/${id}`,
    );
    return response;
  } catch (error) {
    const customError = error as Error;
    Sentry.captureException(customError);
    const statusCode =
      customError.response && customError.response.status
        ? customError.response.status
        : undefined;
    throw new ApiException(statusCode);
  }
};

export const createRoom = async (roomDetail: {
  name: string;
  code: string;
  room_type: string;
  branch_name: string;
  branch_code: string;
  create_username: string;
  is_multi: boolean;
  is_active: boolean;
  sequence: number;
}) => {
  try {
    const response = await axiosInstance.post(
      `${process.env.NEXT_PUBLIC_REACT_APP_PATIENT_FLOW_URL}/rooms`,
      roomDetail,
    );
    return response;
  } catch (error) {
    const customError = error as Error;
    Sentry.captureException(customError);
    const statusCode =
      customError.response && customError.response.status
        ? customError.response.status
        : undefined;
    throw new ApiException(statusCode);
  }
};
export const archiveRoomState = async (
  roomId: number,
  archiveParams: { is_active: boolean; write_username: string },
) => {
  try {
    const response = await axiosInstance.put(
      `${process.env.NEXT_PUBLIC_REACT_APP_PATIENT_FLOW_URL}/rooms/${roomId}`,
      archiveParams,
    );
    return response;
  } catch (error) {
    const customError = error as Error;
    Sentry.captureException(customError);
    const statusCode =
      customError.response && customError.response.status
        ? customError.response.status
        : undefined;
    throw new ApiException(statusCode);
  }
};
export const deleteRoom = async (roomId: number) => {
  try {
    const response = await axiosInstance.delete(
      `${process.env.NEXT_PUBLIC_REACT_APP_PATIENT_FLOW_URL}/rooms/${roomId}`,
    );
    return response;
  } catch (error) {
    const customError = error as Error;
    Sentry.captureException(customError);
    const statusCode =
      customError.response && customError.response.status
        ? customError.response.status
        : undefined;
    throw new ApiException(statusCode);
  }
};
export const updateRoom = async (
  roomId: number,
  roomDetail: {
    name: string;
    code: string;
    room_type: string;
    state: string;
    branch_name: string;
    branch_code: string;
    write_username: string;
  },
) => {
  try {
    const response = await axiosInstance.put(
      `${process.env.NEXT_PUBLIC_REACT_APP_PATIENT_FLOW_URL}/rooms/${roomId}`,
      roomDetail,
    );
    return response;
  } catch (error) {
    const customError = error as Error;
    Sentry.captureException(customError);
    const statusCode =
      customError.response && customError.response.status
        ? customError.response.status
        : undefined;
    throw new ApiException(statusCode);
  }
};

export const fetchAllRooms = async () => {
  try {
    const response = await axiosInstance.get(
      `${process.env.NEXT_PUBLIC_REACT_APP_PATIENT_FLOW_URL}/rooms`,
    );
    return response;
  } catch (error) {
    const customError = error as Error;
    Sentry.captureException(customError);
    const statusCode =
      customError.response && customError.response.status
        ? customError.response.status
        : undefined;
    throw new ApiException(statusCode);
  }
};
export const fetchRoomsByBranchCode = async (branchCode: string) => {
  try {
    const response = await axiosInstance.get(
      `${process.env.NEXT_PUBLIC_REACT_APP_PATIENT_FLOW_URL}/rooms/branch/${branchCode}`,
    );
    return response;
  } catch (error) {
    const customError = error as Error;
    Sentry.captureException(customError);
    const statusCode =
      customError.response && customError.response.status
        ? customError.response.status
        : undefined;
    throw new ApiException(statusCode);
  }
};
export const fetchAvailableRoomsByBranchCode = async (branchCode: string) => {
  try {
    const response = await axiosInstance.get(
      `${process.env.NEXT_PUBLIC_REACT_APP_PATIENT_FLOW_URL}/available-rooms/${branchCode}`,
    );
    return response;
  } catch (error) {
    const customError = error as Error;
    Sentry.captureException(customError);
    const statusCode =
      customError.response && customError.response.status
        ? customError.response.status
        : undefined;
    throw new ApiException(statusCode);
  }
};

export const fetchAllStages = async () => {
  try {
    const response = await memoizedAxiosGet(
      `${process.env.NEXT_PUBLIC_REACT_APP_PATIENT_FLOW_URL}/stages`,
    );
    return response;
  } catch (error) {
    const customError = error as Error;
    Sentry.captureException(customError);
    const statusCode =
      customError.response && customError.response.status
        ? customError.response.status
        : undefined;
    throw new ApiException(statusCode);
  }
};
export const fetchStagesByRoom = async (roomId: number) => {
  try {
    const response = await memoizedAxiosGet(
      `${process.env.NEXT_PUBLIC_REACT_APP_PATIENT_FLOW_URL}/rooms/${roomId}/stages`,
    );
    return response;
  } catch (error) {
    const customError = error as Error;
    Sentry.captureException(customError);
    const statusCode =
      customError.response && customError.response.status
        ? customError.response.status
        : undefined;
    throw new ApiException(statusCode);
  }
};
export const addStageToRoom = async (stageParams: {
  room_id: number;
  stage_id: number;
}) => {
  try {
    const response = await axiosInstance.put(
      `${process.env.NEXT_PUBLIC_REACT_APP_PATIENT_FLOW_URL}/roomStages`,
      stageParams,
    );
    return response;
  } catch (error) {
    const customError = error as Error;
    Sentry.captureException(customError);
    const statusCode =
      customError.response && customError.response.status
        ? customError.response.status
        : undefined;
    throw new ApiException(statusCode);
  }
};
export const removeStageFromRoom = async (
  room_id: number,
  stage_id: number,
) => {
  try {
    const response = await axiosInstance.delete(
      `${process.env.NEXT_PUBLIC_REACT_APP_PATIENT_FLOW_URL}/rooms/${room_id}/stages/${stage_id}`,
    );
    return response;
  } catch (error) {
    const customError = error as Error;
    Sentry.captureException(customError);
    const statusCode =
      customError.response && customError.response.status
        ? customError.response.status
        : undefined;
    throw new ApiException(statusCode);
  }
};
export const fetchProviders = async () => {
  try {
    const response = await memoizedAxiosGet(
      `${process.env.NEXT_PUBLIC_REACT_APP_BACKEND_URL}/providers`,
    );
    return response;
  } catch (error) {
    const customError = error as Error;
    Sentry.captureException(customError);
    const statusCode =
      customError.response && customError.response.status
        ? customError.response.status
        : undefined;
    throw new ApiException(statusCode);
  }
};

export const createBookingTicket = async (checkInParams: CheckInParams) => {
  try {
    const response = await axiosInstance.post(
      `${process.env.NEXT_PUBLIC_REACT_APP_PATIENT_FLOW_URL}/tickets`,
      checkInParams,
    );
    return response;
  } catch (error) {
    const customError = error as Error;
    Sentry.captureException(customError);
    const statusCode =
      customError.response && customError.response.status
        ? customError.response.status
        : undefined;
    throw new ApiException(statusCode);
  }
};
export const transitionBookingTicket = async (
  ticketId: number,
  transitionParams:
    | TransitionParams
    | TransitionParamsToCheckout
    | TransitionParamsByDragging,
) => {
  try {
    const response = await axiosInstance.put(
      `${process.env.NEXT_PUBLIC_REACT_APP_PATIENT_FLOW_URL}/tickets/${ticketId}`,
      transitionParams,
    );
    return response;
  } catch (error) {
    const customError = error as Error;
    Sentry.captureException(customError);
    const statusCode =
      customError.response && customError.response.status
        ? customError.response.status
        : undefined;
    throw new ApiException(statusCode);
  }
};
export const fetchTicketById = async (ticketId: number) => {
  try {
    const response = await axiosInstance.get(
      `${process.env.NEXT_PUBLIC_REACT_APP_PATIENT_FLOW_URL}/tickets/${ticketId}`,
    );
    return response;
  } catch (error) {
    const customError = error as Error;
    Sentry.captureException(customError);
    const statusCode =
      customError.response && customError.response.status
        ? customError.response.status
        : undefined;
    throw new ApiException(statusCode);
  }
};
export const fetchTicketHistory = async (ticketId: number) => {
  try {
    const response = await axiosInstance.get(
      `${process.env.NEXT_PUBLIC_REACT_APP_PATIENT_FLOW_URL}/tickets/${ticketId}/history`,
    );
    return response;
  } catch (error) {
    const customError = error as Error;
    Sentry.captureException(customError);
    const statusCode =
      customError.response && customError.response.status
        ? customError.response.status
        : undefined;
    throw new ApiException(statusCode);
  }
};
export const fetchTicketByPatientNumberAndOrEncounterNo = async (
  hpNumber: string,
  encounterNo = '',
) => {
  try {
    const response = await axiosInstance.get(
      `${process.env.NEXT_PUBLIC_REACT_APP_PATIENT_FLOW_URL}/tickets?patient_number=${hpNumber}&encounter_no=${encounterNo}&state=open`,
    );
    return response;
  } catch (error) {
    const customError = error as Error;
    Sentry.captureException(customError);
    const statusCode =
      customError.response && customError.response.status
        ? customError.response.status
        : undefined;
    throw new ApiException(statusCode);
  }
};

export const updateTicketById = async (
  ticketId: number,
  ticketParams: { state: string; write_username: string },
) => {
  try {
    const response = await axiosInstance.put(
      `${process.env.NEXT_PUBLIC_REACT_APP_PATIENT_FLOW_URL}/tickets/${ticketId}`,
      ticketParams,
    );
    return response;
  } catch (error) {
    const customError = error as Error;
    Sentry.captureException(customError);
    const statusCode =
      customError.response && customError.response.status
        ? customError.response.status
        : undefined;
    throw new ApiException(statusCode);
  }
};
export const updateTicketHistory = async (
  ticketId: number,
  historyId: number,
  historyParams: TicketHistoryUpdate,
) => {
  try {
    const response = await axiosInstance.put(
      `${process.env.NEXT_PUBLIC_REACT_APP_PATIENT_FLOW_URL}/tickets/${ticketId}/history/${historyId}`,
      historyParams,
    );
    return response;
  } catch (error) {
    const customError = error as Error;
    Sentry.captureException(customError);
    const statusCode =
      customError.response && customError.response.status
        ? customError.response.status
        : undefined;
    throw new ApiException(statusCode);
  }
};

export const updateTicketECNumber = async (
  ticketId: number,
  ticketParams: { encounter_no: string; write_username: string },
) => {
  try {
    const response = await axiosInstance.put(
      `${process.env.NEXT_PUBLIC_REACT_APP_PATIENT_FLOW_URL}/tickets/${ticketId}`,
      ticketParams,
    );
    return response;
  } catch (error) {
    const customError = error as Error;
    Sentry.captureException(customError);
    const statusCode =
      customError.response && customError.response.status
        ? customError.response.status
        : undefined;
    throw new ApiException(statusCode);
  }
};

export const fetchProvidersByServiceAndBranch = async (
  branchId: number,
  serviceId: string,
) => {
  try {
    const response = await memoizedAxiosGet(
      `${process.env.NEXT_PUBLIC_REACT_APP_BACKEND_URL}/providers/search?branch_id=${branchId}&service_id=${serviceId}`,
    );
    return response;
  } catch (error) {
    const customError = error as Error;
    Sentry.captureException(customError);
    const statusCode =
      customError.response && customError.response.status
        ? customError.response.status
        : undefined;
    throw new ApiException(statusCode);
  }
};

export const fetchOpenTicketsByBranchCode = async (branchCode: string) => {
  try {
    const response = await axiosInstance.get(
      `${process.env.NEXT_PUBLIC_REACT_APP_PATIENT_FLOW_URL}/get-open-tickets?branch_code=${branchCode}`,
    );
    return response;
  } catch (error) {
    const customError = error as Error;
    Sentry.captureException(customError);
    const statusCode =
      customError.response && customError.response.status
        ? customError.response.status
        : undefined;
    throw new ApiException(statusCode);
  }
};

export const createStage = async (stageParams: {
  name: string;
  is_active: boolean;
  sequence: number;
  create_username: string;
}) => {
  try {
    const response = await axiosInstance.post(
      `${process.env.NEXT_PUBLIC_REACT_APP_PATIENT_FLOW_URL}/stages`,
      stageParams,
    );
    return response;
  } catch (error) {
    const customError = error as Error;
    Sentry.captureException(customError);
    const statusCode =
      customError.response && customError.response.status
        ? customError.response.status
        : undefined;
    throw new ApiException(statusCode);
  }
};
export const fetchStageById = async (stageId: number) => {
  try {
    const response = await memoizedAxiosGet(
      `${process.env.NEXT_PUBLIC_REACT_APP_PATIENT_FLOW_URL}/stages/${stageId}`,
    );
    return response;
  } catch (error) {
    const customError = error as Error;
    Sentry.captureException(customError);
    const statusCode =
      customError.response && customError.response.status
        ? customError.response.status
        : undefined;
    throw new ApiException(statusCode);
  }
};

export const updateStageById = async (
  stageId: number,
  updateParams: { name: string; write_username: string },
) => {
  try {
    const response = await axiosInstance.put(
      `${process.env.NEXT_PUBLIC_REACT_APP_PATIENT_FLOW_URL}/stages/${stageId}`,
      updateParams,
    );
    return response;
  } catch (error) {
    const customError = error as Error;
    Sentry.captureException(customError);
    const statusCode =
      customError.response && customError.response.status
        ? customError.response.status
        : undefined;
    throw new ApiException(statusCode);
  }
};
export const deleteStageById = async (stageId: number) => {
  try {
    const response = await axiosInstance.delete(
      `${process.env.NEXT_PUBLIC_REACT_APP_PATIENT_FLOW_URL}/stages/${stageId}`,
    );
    return response;
  } catch (error) {
    const customError = error as Error;
    Sentry.captureException(customError);
    const statusCode =
      customError.response && customError.response.status
        ? customError.response.status
        : undefined;
    throw new ApiException(statusCode);
  }
};

export const fetchRoomById = async (roomId: number) => {
  try {
    const response = await memoizedAxiosGet(
      `${process.env.NEXT_PUBLIC_REACT_APP_PATIENT_FLOW_URL}/rooms/${roomId}`,
    );
    return response;
  } catch (error) {
    const customError = error as Error;
    Sentry.captureException(customError);
    const statusCode =
      customError.response && customError.response.status
        ? customError.response.status
        : undefined;
    throw new ApiException(statusCode);
  }
};

export const updateRoomById = async (
  roomId: number,
  roomParams: { state: string; write_username: string },
) => {
  try {
    const response = await axiosInstance.put(
      `${process.env.NEXT_PUBLIC_REACT_APP_PATIENT_FLOW_URL}/rooms/${roomId}`,
      roomParams,
    );
    return response;
  } catch (error) {
    const customError = error as Error;
    const message = customError.response && customError.response.data.detail;
    throw new ApiException(undefined, message);
  }
};

export const fetchMessages = async (
  branch_id: number,
  type: string,
  is_active: boolean,
) => {
  try {
    const response = await axios.get(
      `${process.env.NEXT_PUBLIC_REACT_APP_BACKEND_URL}/messages?message_type=${type}&branch_id=${branch_id}&is_active=${is_active}`,
    );
    return response;
  } catch (error) {
    const customError = error as Error;
    Sentry.captureException(customError);
    const statusCode =
      customError.response && customError.response.status
        ? customError.response.status
        : undefined;
    throw new ApiException(statusCode);
  }
};
export const fetchMessagesByCompositionId = async (compositionId: string) => {
  try {
    const response = await memoizedAxiosGet(
      `${process.env.NEXT_PUBLIC_REACT_APP_BACKEND_URL}/messages?composition_id=${compositionId}`,
    );
    return response;
  } catch (error) {
    const customError = error as Error;
    Sentry.captureException(customError);
    const statusCode =
      customError.response && customError.response.status
        ? customError.response.status
        : undefined;
    throw new ApiException(statusCode);
  }
};
export const fetchMessagesByResourceId = async (resourceId: number) => {
  try {
    const response = await axios.get(
      `${process.env.NEXT_PUBLIC_REACT_APP_BACKEND_URL}/messages?message_type=followup&resource_id=${resourceId}&is_active=true`,
    );
    return response;
  } catch (error) {
    const customError = error as Error;
    Sentry.captureException(customError);
    const statusCode =
      customError.response && customError.response.status
        ? customError.response.status
        : undefined;
    throw new ApiException(statusCode);
  }
};
export const fetchMessageById = async (messageId: string) => {
  try {
    const response = await axios.get(
      `${process.env.NEXT_PUBLIC_REACT_APP_BACKEND_URL}/messages/${messageId}`,
    );
    return response;
  } catch (error) {
    const customError = error as Error;
    Sentry.captureException(customError);
    const statusCode =
      customError.response && customError.response.status
        ? customError.response.status
        : undefined;
    throw new ApiException(statusCode);
  }
};
export const createMessage = async (createMessageParams: {
  composition_id: string;
  subject: string;
  body: string;
  message_to: string;
  message_from: string;
  message_type: string;
  email_from: string;
  email_to: string;
  encounter_no: string;
  followup_date: string;
  create_username: string;
  branch_id: number;
}) => {
  try {
    const response = await axiosInstance.post(
      `${process.env.NEXT_PUBLIC_REACT_APP_BACKEND_URL}/messages`,
      createMessageParams,
    );
    return response;
  } catch (error) {
    const customError = error as Error;
    Sentry.captureException(customError);
    const statusCode =
      customError.response && customError.response.status
        ? customError.response.status
        : undefined;
    throw new ApiException(statusCode);
  }
};
export const updateMessage = async (
  message_id: string,
  updateMessageParams: {
    subject: string;
    body: string;
    message_to: string;
    message_from: string;
    message_type: string;
    email_from: string;
    email_to: string;
    encounter_no: string;
    followup_date: string;
    branch_id: number;
    write_username: string;
  },
) => {
  try {
    const response = await axiosInstance.put(
      `${process.env.NEXT_PUBLIC_REACT_APP_BACKEND_URL}/messages/${message_id}`,
      updateMessageParams,
    );
    return response;
  } catch (error) {
    const customError = error as Error;
    Sentry.captureException(customError);
    const statusCode =
      customError.response && customError.response.status
        ? customError.response.status
        : undefined;
    throw new ApiException(statusCode);
  }
};
export const updateMessageBookingId = async (
  message_id: string,
  updateMessageParams: {
    booking_id: number;
    write_username: string;
  },
) => {
  try {
    const response = await axiosInstance.put(
      `${process.env.NEXT_PUBLIC_REACT_APP_BACKEND_URL}/messages/${message_id}`,
      updateMessageParams,
    );
    return response;
  } catch (error) {
    const customError = error as Error;
    Sentry.captureException(customError);
    const statusCode =
      customError.response && customError.response.status
        ? customError.response.status
        : undefined;
    throw new ApiException(statusCode);
  }
};
export const deleteMessage = async (message_id: string) => {
  try {
    const response = await axiosInstance.delete(
      `${process.env.NEXT_PUBLIC_REACT_APP_BACKEND_URL}/messages/${message_id}`,
    );
    return response;
  } catch (error) {
    const customError = error as Error;
    Sentry.captureException(customError);
    const statusCode =
      customError.response && customError.response.status
        ? customError.response.status
        : undefined;
    throw new ApiException(statusCode);
  }
};

export const deactivateMessage = async (
  message_id: string,
  deactivateParams: {
    is_active: boolean;
    write_username: string;
  },
) => {
  try {
    const response = await axiosInstance.put(
      `${process.env.NEXT_PUBLIC_REACT_APP_BACKEND_URL}/messages/${message_id}`,
      deactivateParams,
    );
    return response;
  } catch (error) {
    const customError = error as Error;
    Sentry.captureException(customError);
    const statusCode =
      customError.response && customError.response.status
        ? customError.response.status
        : undefined;
    throw new ApiException(statusCode);
  }
};
