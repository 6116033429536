import React from 'react';
import Button from 'react-bootstrap/Button';
import LoadingSpinner from 'src/common/components/loading/LoadingSpinner';

interface FormButtonProps {
  children: React.ReactNode;
  className?: string;
  loading?: boolean;
  active?: boolean;
  as?: any;
  disabled?: boolean;
  href?: string;
  size?: any;
  type?: any;
  variant?: string;
  onClick?: (ev: any) => void;
  role?: string;
}

const FormButton = ({
  role,
  children,
  loading,
  className,
  ...otherProps
}: FormButtonProps) => (
  <Button
    className={(loading ? 'loading ' : '') + (className ? className : '')}
    {...otherProps}
    role={role}>
    {loading && (
      <span className="loading-dots">
        <LoadingSpinner size="sm"></LoadingSpinner>
        <LoadingSpinner size="sm"></LoadingSpinner>
        <LoadingSpinner size="sm"></LoadingSpinner>
      </span>
    )}
    {children}
  </Button>
);

export default FormButton;
