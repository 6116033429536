import React, { useEffect, useRef, useState } from 'react';
import Image from 'next/image';
import Link from 'next/link';
import { permissions } from 'src/common/contexts/auth';
import { useSession } from 'next-auth/react';
import jwt_decode from 'jwt-decode';
import { ISession } from 'src/common/interfaces/session';

interface DecodedToken {
  resource_access: {
    ehacare: {
      roles: string[];
    };
  };
}

const LinkNavigation = ({ section }: { section: string }) => {
  const navRef = useRef<HTMLDivElement | null>(null);
  const homeRef = useRef<HTMLDivElement | null>(null);
  const [open, setOpen] = useState(false);

  const { data: session } = useSession() as unknown as ISession;

  const hasPermission = (permission: string) => {
    if (session) {
      const token = session?.accessToken;
      const decodedToken: DecodedToken = jwt_decode(token);
      const { roles } = decodedToken.resource_access.ehacare;
      return roles.includes(permission);
    }
  };

  useEffect(() => {
    let isMounted = true;
    let handler: (e: MouseEvent) => void = (e) => {
      if (navRef.current && homeRef.current) {
        if (!navRef?.current?.contains(e.target as Node) && isMounted) {
          setOpen(false);
        }
      }
    };
    document.addEventListener('mousedown', handler);
    return () => {
      isMounted = false;
      removeEventListener('mousedown', handler);
    };
  }, []);

  const Homehandler = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    setOpen(false);
    e.stopPropagation();
  };

  const Linkhandler = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    setOpen(false);
    e.stopPropagation();
  };

  return (
    <div className="cust-nav-dropdown" ref={navRef}>
      <div
        className="cust-nav-dropdown-logo"
        onClick={() => setOpen((prev) => !prev)}>
        <div id="cust-home-logo" ref={homeRef}>
          <Link href="/" passHref>
            <a className="" onClick={Homehandler}>
              {section === 'ambulatory-app' || section === 'settings' ? (
                <Image
                  src={require('/public/img/Home.svg')}
                  width={27}
                  height={25}
                  className="d-inline-block align-center mt-1"
                  alt="Eha Home"
                />
              ) : (
                <Image
                  src={require('/public/img/Blue-Home.svg')}
                  width={27}
                  height={27}
                  className="d-inline-block align-center mt-1"
                  alt="Eha Home"
                />
              )}
            </a>
          </Link>
        </div>
        <div
          className={`${
            section === 'quality-app'
              ? 'cust-seperator-qapp '
              : 'cust-seperator'
          }`}
        />
        {section === 'ambulatory-app' ? (
          <div className="ln-eha-logo">
            <Image
              src={require('/public/img/EHACare-logo.svg')}
              width={110}
              height={25}
              className="d-inline-block align-center "
              alt="Eha Care"
              test-id="ehacare-logo"
            />
          </div>
        ) : null}

        {section === 'settings' ? (
          <div className="settings-logo">
            <div className="settings-logo-main">
              <Image
                src={require('/public/img/settings-config-image.svg')}
                alt=""
                className=""
                width={30}
                height={24}
              />
              <div className="settings-text-svg">
                <Image
                  src={require('/public/img/settings-config-text.svg')}
                  alt=""
                  className=""
                  width={90}
                  height={28}
                />
              </div>
            </div>
          </div>
        ) : null}

        {section === 'quality-app' ? (
          <Image
            src={require('/public/img/EHAQA-logo.svg')}
            width={110}
            height={23}
            className="d-inline-block align-center "
            alt="Quality App"
            test-id="ehacare-logo"
          />
        ) : null}

        <i
          className={`${open ? 'eha-up' : 'eha-down'} cust-nav-dropdown-arrow`}
        />
      </div>

      <div
        className={`cust-nav-dropdown-menu ${open ? 'active' : 'inactive'}`}
        onClick={Linkhandler}>
        <LinkItem title="Patient Management" href="/ambulatory-app">
          <div className="">
            <img
              src="/img/EHACare-logo-black.svg"
              alt=""
              className="my-2"
              height="24px"></img>
          </div>
        </LinkItem>
        <Divider />
        <LinkItem
          title="Configuration & Settings"
          href="/settings/ambulatory-care/user-admin/users">
          <div className="position-relative  mb-3" style={{ height: '40px' }}>
            <div className="mt-2 mb-2 d-flex justify-content-center align-items-center gap-2">
              <img
                src="/img/EHACare-config-nut-icon.svg"
                alt=""
                className=""
                height="24px"
              />
              <img
                src="/img/EHAcare-text.svg"
                alt=""
                className=""
                height="14px"
              />
            </div>

            <div className="config-text">Configuration</div>
          </div>
        </LinkItem>
        <Divider />
        {hasPermission(permissions.EHA_QUALITY_APP) && (
          <LinkItem title="Review, Audit & Report" href="/quality-app">
            <div className="">
              <img
                src="/img/EHAQA-green.svg"
                alt=""
                className="my-2"
                height="24px"></img>
            </div>
          </LinkItem>
        )}
        {hasPermission(permissions.EHA_QUALITY_APP) && <Divider />}
        <LinkItem
          title="Booking Management"
          href={process.env.NEXT_PUBLIC_BOOKING_URL as string}>
          <div className="">
            <img
              src="/img/EHAQA-booking.svg"
              alt=""
              className="my-2"
              height="24px"></img>
          </div>
        </LinkItem>
      </div>
    </div>
  );
};

export default LinkNavigation;

type LinkItemProps = {
  children: React.ReactNode;
  title: string;
  href: string;
};

const LinkItem = ({ children, title, href }: LinkItemProps) => {
  return (
    <Link href={href} passHref>
      <a className="d-flex flex-column align-items-start cust-nav-dropdown-item">
        <span className="label">{title}</span>
        {children}
      </a>
    </Link>
  );
};

const Divider = () => {
  return (
    <div style={{ width: '90%', margin: 'auto' }} className="border my-1" />
  );
};
